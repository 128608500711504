<template>
  <div>
    <!--Progress Bar-->
    <v-dialog
      v-model="progress"
      max-width="290"
      persistent
      hide-overlay
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading Customers
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-toolbar
      flat
      color="white"
    >
      <!--  Search Box -->
      <v-form
        @submit.prevent="runSearch"
      >
        <v-text-field
          v-model="search"
          label="Search"
          prepend-icon="fal fa-search"
          :append-icon="search ? 'fal fa-times-circle' : undefined"
          single-line
          hide-details
          @click:prepend="runSearch"
          @click:append="clearSearch"
        />
      </v-form>

      <v-spacer />

      <v-btn
        color="green lighten-1"
        rounded
        dark
        class="mb-2"
      >
        New
      </v-btn>

      <!-- Delete Dialog Box -->
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Delete this record?</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  "{{ editedItem.d_companyName }}" will be deleted from the system!
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color=""
              text
              @click.native="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click.native="deleteItem"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- Data Table -->
    <v-data-table
      :headers="headers"
      :items="customersStore.customers"
      hide-default-footer
      class="elevation-0"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.id }}</td>
        <td class="text-xs">
          {{ props.item.d_companyName }}
        </td>
        <td class="justify-end layout px-3">
          <v-icon
            small
            class="mr-4"
            color="secondary"
            @click="editItem(props.item)"
          >
            far fa-pencil-alt
          </v-icon>
          <v-icon
            small
            color="error"
            @click="openDeleteDialog(props.item)"
          >
            fal fa-trash-alt
          </v-icon>
        </td>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="error"
        icon="far fa-exclamation-triangle"
      >
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Countries',
  computed: {
    ...mapState({
      customersStore: (state) => state.customersStore,
    }),
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      progress: false,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Company Name',
          align: 'left',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'name',
          sortable: false,
        },
      ],
      editFlag: -1,
      editedItem: {
        id: '',
        d_companyName: '',
      },
      defaultItem: {
        id: '',
        d_companyName: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
    	this.progress = true;
      /* Load records */
      await this.$store.dispatch('customersStore/fetch');
      this.progress = false;
    },

    editItem(item) {
      this.editFlag = 1;
      this.editedItem = { ...item };
      this.dialog = true;
    },

    openDeleteDialog(item) {
      this.editedItem = { ...item };
      this.deleteDialog = true;
    },

    close() {
      this.dialog = false;
      this.deleteDialog = false;
      setTimeout(() => {
        this.editedItem = { ...this.defaultItem };
        this.editFlag = -1;
      }, 300);
    },

    async deleteItem() {
      const data = {
        id: this.editedItem.id,
      };
      await this.$store.dispatch('customersStore/delete', data);
      this.close();
    },

    async runSearch() {
      if (this.search) {
        const searchQuery = {
          search: this.search,
        };
        await this.$store.dispatch('customersStore/query', searchQuery);
      } else {
        await this.$store.dispatch('customersStore/fetch');
      }
    },

    clearSearch() {
      this.search = '';
      this.initialize();
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
